import {
  Image,
  notDesktop,
  styled,
  Text,
  useIsClient,
  useIsMobile,
} from "@obvio/app";
import { SvgMail, SvgPhone } from "@obvio/svg";
import { Button, Grid, Stack } from "@obvio/ui";
import { motion } from "framer-motion";

import type { ImageAsset } from "@obvio/app";
import type { ReactElement } from "react";

type Member = {
  name: string;
  phone: string;
  email: string;
  image: ImageAsset[];
};
type TeamProps = {
  members: { title: string; data: Member }[];
};

const Wrap = motion(styled.div`
  display: flex;
  flex-direction: column;
  height: 34.8125rem;
`);

const Content = motion(styled.div`
  background-color: ${(theme) => theme.colors.primary};
  padding: ${(theme) => theme.spacing.big};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  * {
    color: white;
    font-size: 0.875rem;
  }
  @media ${notDesktop} {
    height: 30%;
  }
`);

const ImageWrap = styled.div`
  background-color: red;
  flex: 1;
  position: relative;
`;

const GridWrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
	}
	}
`;

const Pills = motion(styled(Stack)`
  position: absolute;
  bottom: ${(theme) => theme.spacing.medium};
  left: ${(theme) => theme.spacing.medium};
  z-index: 2;
`);

const Pill = styled(Button)`
  display: flex;
  width: 56px;
  height: 44px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 50px;
  background: rgba(140, 140, 140, 0.4);
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  svg {
    transition: transform 0.2s ease-in-out;
  }
`;

const variants = {
  initial: {
    height: "60%",
  },
  mobileInitial: {
    height: "30%",
  },
  hover: {
    height: "30%",
  },
};

function Card({ member }: { member: Member }) {
  const isClient = useIsClient();
  const isMobile = useIsMobile();

  if (!isClient) {
    return null;
  }
  return (
    <Wrap
      initial={isMobile ? "mobileInitial" : "initial"}
      whileHover={isMobile ? "mobileHover" : "hover"}
    >
      <Content variants={variants}>
        <Text>{member.name}</Text>
        <Stack kind="vertical" spacing="0.1rem">
          <Text>{member.phone}</Text>
          <Text>{member.email}</Text>
        </Stack>
      </Content>
      <ImageWrap>
        <Image
          img={member.image[0]}
          objectFit="cover"
          objectPosition="center"
        />
        <Pills
          variants={{
            initial: {
              opacity: 0,
            },
            hover: {
              opacity: 1,
            },
          }}
        >
          <Pill kind={["icon", "transparent"]} href={`tel:${member.phone}`}>
            <SvgPhone />
          </Pill>
          <Pill kind={["icon", "transparent"]} href={`mailto:${member.email}`}>
            <SvgMail />
          </Pill>
        </Pills>
      </ImageWrap>
    </Wrap>
  );
}

export function Team({ members }: TeamProps): ReactElement {
  return (
    <GridWrap templateColumns="1fr 1fr 1fr" gap="medium">
      {members.map((member) => (
        <Card member={member.data} key={member.title} />
      ))}
    </GridWrap>
  );
}
